import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import cx from 'clsx';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { InformationIcon } from '../../Icons';

const highlightedStoreColors = ['#12161A', '#2C04FF', '#A83BE2'];

const styles = theme => ({
  root: {
    height: 351,
  },
  legendContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    width: '100%',
  },
  legend: {
    border: '1px solid rgba(0, 0, 0, 0.16)',
    borderRadius: 14,
    padding: '5px 12px',
    marginBottom: 6,
    marginRight: 4,
    fontWeight: 400,
    fontSize: 12,
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.65)',
    cursor: 'pointer',
    '&.highlight0': {
      background: `${highlightedStoreColors[0]}`,
      color: '#FFFFFF',
    },
    '&.highlight1': {
      background: `${highlightedStoreColors[1]}`,
      color: '#FFFFFF',
    },
    '&.highlight2': {
      background: `${highlightedStoreColors[2]}`,
      color: '#FFFFFF',
    },
    '&.disableLegend': {
      background: 'rgba(0, 0, 0, 0.04)',
      border: '1px solid rgba(0, 0, 0, 0)',
      color: 'rgba(0, 0, 0, 0.16)',
      pointerEvents: 'none',
    },
  },
  subText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
    marginTop: 16,
    '& .text': {
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: 10,
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
});

const RankingChart = ({
  classes,
  highestRank,
  lowestRank,
  data,
  storeList,
  onChartInfoIconClick,
}) => {
  const [highlightedStore, setHighlightedStore] = useState([]);

  const getLineColor = storeName => {
    if (storeName === highestRank) return '#00DDB0';
    if (storeName === lowestRank) return '#F96282';
    return getHighlightStoreColor(storeName, 'line');
  };

  const getHighlightStoreColor = (storeName, type) => {
    if (highlightedStore.includes(storeName)) {
      const index = highlightedStore.indexOf(storeName);
      return highlightedStoreColors[index];
    }
    if (type === 'legend') {
      return '#FFFFFF';
    }
    if (type === 'line') {
      return 'rgba(0, 0, 0, 0.04)';
    }
  };

  const disableStoreLegendSelection = storeName => {
    if (highlightedStore.includes(storeName)) {
      const index = highlightedStore.indexOf(storeName);
      return `highlight${index}`;
    } else {
      if (highlightedStore.length === 3) {
        return 'disableLegend';
      }
      return '';
    }
  };

  const handleLegendClick = storeName => {
    const highlightedStoreList = [...highlightedStore];
    const index = highlightedStoreList.indexOf(storeName);
    if (index > -1) {
      highlightedStoreList.splice(index, 1);
    } else {
      highlightedStoreList.push(storeName);
    }
    setHighlightedStore(highlightedStoreList);
  };

  return (
    <div>
      <ResponsiveContainer className={classes.root} height={310} width="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 0,
            right: -30,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="rgba(0, 0, 0, 0.04)" />
          {storeList.map(storeName => (
            <Line
              type="linear"
              dataKey={storeName}
              stroke={getLineColor(storeName)}
              dot={
                highlightedStore.includes(storeName)
                  ? { r: 8, fill: getHighlightStoreColor(storeName, 'legend') }
                  : false
              }
              label={storeName}
              strokeDasharray={
                [highestRank, lowestRank].includes(storeName) ? '3 3' : false
              }
              strokeWidth={highlightedStore.includes(storeName) ? 2 : 1}
            />
          ))}
          <XAxis dataKey="period" tickLine={false} tickCount={2} />
          <YAxis orientation="right" tickCount={3} tickLine={false} />
        </LineChart>
      </ResponsiveContainer>
      <div className={classes.subText}>
        <label className="text">Highlight Store</label>
        <label>
          <InformationIcon onClick={onChartInfoIconClick} />
        </label>
      </div>
      <div className={classes.legendContainer}>
        {storeList.map(storeName => (
          <div
            className={cx(
              classes.legend,
              `${disableStoreLegendSelection(storeName)}`
            )}
            onClick={() => handleLegendClick(storeName)}
          >
            {storeName}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles, { name: 'LriRankingChart' })(RankingChart);
