import React from 'react';
import { SvgIcon } from '@material-ui/core';

/**
 * The recommended way is to wrap the SVG path in a <SvgIcon /> MUI component
 * https://material-ui.com/components/icons/#svgicon
 */

export const ClockIcon = props => (
  <SvgIcon viewBox="0 0 23 23" {...props}>
    <path d="M12,5c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.411,5,12,5z M12,19c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6 S15.309,19,12,19z"></path>
    <path d="M11 9H13V14H11zM9 2H15V4H9z"></path>
    <path
      transform="rotate(-134.999 19 6)"
      d="M17.586 5H20.413999999999998V7H17.586z"
    ></path>
  </SvgIcon>
);

export const KPIsIcon = props => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <rect x="4" y="4" width="11" height="11" />
    <rect x="17" y="4" width="11" height="11" />
    <rect x="4" y="17" width="11" height="11" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 19H19V26H26V19ZM17 17V28H28V17H17Z"
    />
  </SvgIcon>
);

export const ProductsIcon = props => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path d="M25.9 28L17.8 28.5L16.7 15H15.2L14.1 28.5L6 28L7.2 4H24.7L25.9 28Z" />
  </SvgIcon>
);

export const CompareIcon = props => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <circle cx="23" cy="16" r="6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 20C11.2091 20 13 18.2091 13 16C13 13.7909 11.2091 12 9 12C6.79086 12 5 13.7909 5 16C5 18.2091 6.79086 20 9 20ZM9 22C12.3137 22 15 19.3137 15 16C15 12.6863 12.3137 10 9 10C5.68629 10 3 12.6863 3 16C3 19.3137 5.68629 22 9 22Z"
    />
  </SvgIcon>
);

export const RankingIcon = props => (
  <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path d="M16.5 6L18.79 8.29L13.91 13.17L9.91 9.17L2.5 16.59L3.91 18L9.91 12L13.91 16L20.21 9.71L22.5 12V6H16.5Z" />
  </SvgIcon>
);

export const CollapseButtonIcon = props => (
  <SvgIcon viewBox="0 0 10 6" {...props}>
    <path d="M8.44531 0.433594L9.5 1.48828L5 5.98828L0.5 1.48828L1.55469 0.433594L5 3.87891L8.44531 0.433594Z" />
  </SvgIcon>
);

export const Arrow = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path d="M13.0117 6.26172V7.73828H3.87109L8.05469 11.957L7 13.0117L0.988281 7L7 0.988281L8.05469 2.04297L3.87109 6.26172H13.0117Z" />
  </SvgIcon>
);

export const Avatar = props => (
  <SvgIcon viewBox="-3 -3 24 24" {...props}>
    <path d="M3.56979 12.1406C5.41354 11.3906 7.17917 11.0156 8.86667 11.0156C10.5542 11.0156 12.3042 11.3906 14.1167 12.1406C15.9604 12.8594 16.8823 13.8125 16.8823 15V17.0156H0.851044V15C0.851044 13.8125 1.75729 12.8594 3.56979 12.1406ZM11.6792 7.82812C10.8979 8.60938 9.96042 9 8.86667 9C7.77292 9 6.83542 8.60938 6.05417 7.82812C5.27292 7.04688 4.88229 6.10938 4.88229 5.01562C4.88229 3.92188 5.27292 2.98438 6.05417 2.20312C6.83542 1.39063 7.77292 0.984375 8.86667 0.984375C9.96042 0.984375 10.8979 1.39063 11.6792 2.20312C12.4604 2.98438 12.851 3.92188 12.851 5.01562C12.851 6.10938 12.4604 7.04688 11.6792 7.82812Z" />
  </SvgIcon>
);

export const Close = props => (
  <SvgIcon viewBox="0 0 12 12" {...props}>
    <path d="M11.2383 1.81641L7.05469 6L11.2383 10.1836L10.1836 11.2383L6 7.05469L1.81641 11.2383L0.761719 10.1836L4.94531 6L0.761719 1.81641L1.81641 0.761719L6 4.94531L10.1836 0.761719L11.2383 1.81641Z" />
  </SvgIcon>
);

export const Vector = props => (
  <SvgIcon viewBox="0 0 8 12" {...props}>
    <path d="M1.93708 11.833C1.7058 11.8324 1.47987 11.7633 1.28767 11.6344C1.09547 11.5055 0.945577 11.3225 0.856823 11.1085C0.768068 10.8945 0.744413 10.659 0.788829 10.4315C0.833246 10.2041 0.943752 9.99488 1.10646 9.83018L4.63137 6.29786L1.10646 2.77075C0.99261 2.66288 0.901471 2.53324 0.838425 2.3895C0.77538 2.24576 0.741704 2.09082 0.739386 1.93382C0.737068 1.77683 0.766155 1.62095 0.824929 1.47541C0.883704 1.32986 0.970975 1.19758 1.08159 1.08638C1.19221 0.975182 1.32394 0.887313 1.469 0.82796C1.61406 0.768606 1.76951 0.738971 1.92618 0.740803C2.08286 0.742634 2.23758 0.775895 2.38121 0.838623C2.52485 0.901351 2.6545 0.992276 2.7625 1.10603L7.95382 6.30827L2.7625 11.5105C2.65448 11.6197 2.52588 11.7063 2.38418 11.7652C2.24248 11.8242 2.0905 11.8543 1.93708 11.8539" />
  </SvgIcon>
);
export const Sort = props => (
  <SvgIcon viewBox="0 0 18 12" {...props}>
    <path d="M0 6.98438V5.01562H12V6.98438H0ZM0 0H18V2.01562H0V0ZM0 12V9.98438H6V12H0Z" />
  </SvgIcon>
);
export const ArrowDownIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="black"
      fillOpacity="0.04"
    />
    <path
      d="M15.4425 9.97131L12 13.4063L8.5575 9.97131L7.5 11.0288L12 15.5288L16.5 11.0288L15.4425 9.97131Z"
      fill="black"
      fillOpacity="0.3"
    />
  </SvgIcon>
);

export const TrendsLineIcon = props => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="#ff0000"
      stroke="none"
    >
      <path
        d="M90 320 l0 -230 230 0 c225 0 230 0 230 20 0 20 -5 21 -207 20 l-208
-2 -3 211 c-2 203 -3 211 -22 211 -19 0 -20 -7 -20 -230z"
      />
      <path
        d="M449 529 c-8 -16 -8 -27 1 -44 10 -19 9 -32 -6 -70 -9 -26 -23 -49
-31 -52 -18 -7 -83 44 -83 66 0 42 -61 57 -81 20 -8 -16 -8 -28 0 -45 15 -34
-20 -146 -54 -168 -31 -20 -32 -50 -3 -70 37 -26 84 16 60 55 -14 22 23 155
46 164 23 8 82 -36 82 -62 0 -27 34 -53 61 -46 23 6 35 44 21 69 -14 26 16
110 45 123 25 12 31 49 11 69 -19 19 -56 14 -69 -9z"
      />
    </g>
  </SvgIcon>
);

export const DistrictViewIcon = props => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_8363_41030)">
      <path
        d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9Z"
        fill="#FFFFFF"
      />
      <path
        d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
        fill="#FFFFFF"
      />
      <path
        d="M13.8817 17.2857C13.8817 16.882 13.6389 16.5179 13.2663 16.3627C12.8937 16.2074 12.4643 16.2914 12.1776 16.5756L9.29592 19.4327C9.10653 19.6205 9 19.8762 9 20.1429C9 20.4096 9.10653 20.6652 9.29592 20.853L12.1776 23.7101C12.4643 23.9943 12.8937 24.0783 13.2663 23.9231C13.6389 23.7678 13.8817 23.4037 13.8817 23V21.8571H17.9444C18.4967 21.8571 18.9444 21.4094 18.9444 20.8571V19.4286C18.9444 18.8763 18.4967 18.4286 17.9444 18.4286H13.8817V17.2857ZM23.7041 16.5673C23.8935 16.3795 24 16.1238 24 15.8571C24 15.5904 23.8935 15.3348 23.7041 15.147L20.8224 12.2899C20.5357 12.0057 20.1063 11.9217 19.7337 12.0769C19.3611 12.2322 19.1183 12.5963 19.1183 13V14.1429H15.0556C14.5033 14.1429 14.0556 14.5906 14.0556 15.1429V16.5714C14.0556 17.1237 14.5033 17.5714 15.0556 17.5714H19.1183V18.7143C19.1183 19.118 19.3611 19.4821 19.7337 19.6373C20.1063 19.7926 20.5357 19.7086 20.8224 19.4244L23.7041 16.5673Z"
        fill="#FFFFFF"
        stroke="#1419D0"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8363_41030">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const DistrictViewIconLight = props => (
  <SvgIcon width="62" height="62" viewBox="0 0 62 62" fill="none" {...props}>
    <g clipPath="url(#clip0_9083_20256)">
      <path
        d="M30.9998 5.16663C21.0023 5.16663 12.9165 13.2525 12.9165 23.25C12.9165 36.8125 30.9998 56.8333 30.9998 56.8333C30.9998 56.8333 49.0832 36.8125 49.0832 23.25C49.0832 13.2525 40.9973 5.16663 30.9998 5.16663ZM18.0832 23.25C18.0832 16.12 23.8698 10.3333 30.9998 10.3333C38.1298 10.3333 43.9165 16.12 43.9165 23.25C43.9165 30.69 36.4765 41.8241 30.9998 48.7733C25.6265 41.8758 18.0832 30.6125 18.0832 23.25Z"
        fill="#11161A"
      />
      <path
        d="M30.9998 29.7083C34.5667 29.7083 37.4582 26.8168 37.4582 23.25C37.4582 19.6831 34.5667 16.7916 30.9998 16.7916C27.433 16.7916 24.5415 19.6831 24.5415 23.25C24.5415 26.8168 27.433 29.7083 30.9998 29.7083Z"
        fill="#11161A"
      />
      <path
        d="M34.7778 44.6548C34.7778 44.0493 34.4137 43.5031 33.8548 43.2702C33.2958 43.0373 32.6517 43.1633 32.2217 43.5896L24.7774 50.9706C24.4933 51.2522 24.3335 51.6357 24.3335 52.0358C24.3335 52.4358 24.4933 52.8193 24.7774 53.1009L32.2217 60.4819C32.6517 60.9082 33.2958 61.0342 33.8548 60.8013C34.4137 60.5684 34.7778 60.0222 34.7778 59.4167L34.7778 55.381H46.3566C47.1851 55.381 47.8566 54.7094 47.8566 53.881V50.1905C47.8566 49.3621 47.1851 48.6905 46.3566 48.6905H34.7778V44.6548ZM60.4729 42.0295C60.757 41.7478 60.9168 41.3644 60.9168 40.9643C60.9168 40.5643 60.757 40.1808 60.4729 39.8991L53.0286 32.5182C52.5986 32.0919 51.9545 31.9659 51.3955 32.1988C50.8366 32.4317 50.4725 32.9778 50.4725 33.5834V37.6191H38.8937C38.0653 37.6191 37.3937 38.2907 37.3937 39.1191V42.8096C37.3937 43.638 38.0653 44.3096 38.8937 44.3096H50.4725V48.3453C50.4725 48.9508 50.8366 49.497 51.3955 49.7299C51.9545 49.9628 52.5986 49.8368 53.0286 49.4105L60.4729 42.0295Z"
        fill="#11161A"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9083_20256">
        <rect width="62" height="62" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const StoreViewIcon = props => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_10381_6010)">
      <path
        d="M21.8978 8.89L20.8478 4.52C20.6278 3.62 19.8478 3 18.9378 3H5.04781C4.14781 3 3.35781 3.63 3.14781 4.52L2.09781 8.89C1.85781 9.91 2.07781 10.95 2.71781 11.77C2.79781 11.88 2.90781 11.96 2.99781 12.06V19C2.99781 20.1 3.89781 21 4.99781 21H18.9978C20.0978 21 20.9978 20.1 20.9978 19V12.06C21.0878 11.97 21.1978 11.88 21.2778 11.78C21.9178 10.96 22.1478 9.91 21.8978 8.89ZM18.9078 4.99L19.9578 9.36C20.0578 9.78 19.9678 10.2 19.7078 10.53C19.5678 10.71 19.2678 11 18.7678 11C18.1578 11 17.6278 10.51 17.5578 9.86L16.9778 5L18.9078 4.99ZM12.9978 5H14.9578L15.4978 9.52C15.5478 9.91 15.4278 10.3 15.1678 10.59C14.9478 10.85 14.6278 11 14.2178 11C13.5478 11 12.9978 10.41 12.9978 9.69V5ZM8.48781 9.52L9.03781 5H10.9978V9.69C10.9978 10.41 10.4478 11 9.70781 11C9.36781 11 9.05781 10.85 8.81781 10.59C8.56781 10.3 8.44781 9.91 8.48781 9.52ZM4.03781 9.36L5.04781 5H7.01781L6.43781 9.86C6.35781 10.51 5.83781 11 5.22781 11C4.73781 11 4.42781 10.71 4.29781 10.53C4.02781 10.21 3.93781 9.78 4.03781 9.36ZM4.99781 19V12.97C5.07781 12.98 5.14781 13 5.22781 13C6.09781 13 6.88781 12.64 7.46781 12.05C8.06781 12.65 8.86781 13 9.77781 13C10.6478 13 11.4278 12.64 12.0078 12.07C12.5978 12.64 13.3978 13 14.2978 13C15.1378 13 15.9378 12.65 16.5378 12.05C17.1178 12.64 17.9078 13 18.7778 13C18.8578 13 18.9278 12.98 19.0078 12.97V19H4.99781Z"
        fill="white"
      />
      <path
        d="M13.8817 17.2857C13.8817 16.882 13.6389 16.5179 13.2663 16.3627C12.8937 16.2074 12.4643 16.2914 12.1776 16.5756L9.29592 19.4327C9.10653 19.6205 9 19.8762 9 20.1429C9 20.4096 9.10653 20.6652 9.29592 20.853L12.1776 23.7101C12.4643 23.9943 12.8937 24.0783 13.2663 23.9231C13.6389 23.7678 13.8817 23.4037 13.8817 23V21.8571H17.9444C18.4967 21.8571 18.9444 21.4094 18.9444 20.8571V19.4286C18.9444 18.8763 18.4967 18.4286 17.9444 18.4286H13.8817V17.2857ZM23.7041 16.5673C23.8935 16.3795 24 16.1238 24 15.8571C24 15.5904 23.8935 15.3348 23.7041 15.147L20.8224 12.2899C20.5357 12.0057 20.1063 11.9217 19.7337 12.0769C19.3611 12.2322 19.1183 12.5963 19.1183 13V14.1429H15.0556C14.5033 14.1429 14.0556 14.5906 14.0556 15.1429V16.5714C14.0556 17.1237 14.5033 17.5714 15.0556 17.5714H19.1183V18.7143C19.1183 19.118 19.3611 19.4821 19.7337 19.6373C20.1063 19.7926 20.5357 19.7086 20.8224 19.4244L23.7041 16.5673Z"
        fill="white"
        stroke="#131ad0"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10381_6010">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const StoreViewIconLight = props => (
  <SvgIcon width="62" height="62" viewBox="0 0 62 62" fill="none" {...props}>
    <g clipPath="url(#clip0_9083_20263)">
      <path
        d="M56.5693 22.9658L53.8568 11.6767C53.2884 9.35167 51.2734 7.75 48.9226 7.75H13.0401C10.7151 7.75 8.67428 9.3775 8.13178 11.6767L5.41928 22.9658C4.79928 25.6008 5.36761 28.2875 7.02095 30.4058C7.22761 30.69 7.51178 30.8967 7.74428 31.155V49.0833C7.74428 51.925 10.0693 54.25 12.9109 54.25H49.0776C51.9193 54.25 54.2443 51.925 54.2443 49.0833V31.155C54.4768 30.9225 54.7609 30.69 54.9676 30.4317C56.6209 28.3133 57.2151 25.6008 56.5693 22.9658ZM48.8451 12.8908L51.5576 24.18C51.8159 25.265 51.5834 26.35 50.9118 27.2025C50.5501 27.6675 49.7751 28.4167 48.4834 28.4167C46.9076 28.4167 45.5384 27.1508 45.3576 25.4717L43.8593 12.9167L48.8451 12.8908ZM33.5776 12.9167H38.6409L40.0359 24.5933C40.1651 25.6008 39.8551 26.6083 39.1834 27.3575C38.6151 28.0292 37.7884 28.4167 36.7293 28.4167C34.9984 28.4167 33.5776 26.8925 33.5776 25.0325V12.9167ZM21.9268 24.5933L23.3476 12.9167H28.4109V25.0325C28.4109 26.8925 26.9901 28.4167 25.0784 28.4167C24.2001 28.4167 23.3993 28.0292 22.7793 27.3575C22.1334 26.6083 21.8234 25.6008 21.9268 24.5933ZM10.4309 24.18L13.0401 12.9167H18.1293L16.6309 25.4717C16.4243 27.1508 15.0809 28.4167 13.5051 28.4167C12.2393 28.4167 11.4384 27.6675 11.1026 27.2025C10.4051 26.3758 10.1726 25.265 10.4309 24.18ZM12.9109 49.0833V33.5058C13.1176 33.5317 13.2984 33.5833 13.5051 33.5833C15.7526 33.5833 17.7934 32.6533 19.2918 31.1292C20.8418 32.6792 22.9084 33.5833 25.2593 33.5833C27.5068 33.5833 29.5218 32.6533 31.0201 31.1808C32.5443 32.6533 34.6109 33.5833 36.9359 33.5833C39.1059 33.5833 41.1726 32.6792 42.7226 31.1292C44.2209 32.6533 46.2618 33.5833 48.5093 33.5833C48.716 33.5833 48.8968 33.5317 49.1034 33.5058V49.0833H12.9109Z"
        fill="#11161A"
      />
      <path
        d="M35.2778 44.6548C35.2778 43.8474 34.7924 43.1192 34.0471 42.8087C33.3019 42.4981 32.443 42.6661 31.8696 43.2346L24.4253 50.6155C24.0466 50.9911 23.8335 51.5024 23.8335 52.0358C23.8335 52.5692 24.0466 53.0804 24.4253 53.456L31.8696 60.837C32.443 61.4054 33.3019 61.5734 34.0471 61.2628C34.7924 60.9523 35.2778 60.2241 35.2778 59.4167V55.881H46.3566C47.4612 55.881 48.3566 54.9856 48.3566 53.881V50.1905C48.3566 49.0859 47.4612 48.1905 46.3566 48.1905H35.2778V44.6548ZM60.825 42.3846C61.2038 42.009 61.4168 41.4977 61.4168 40.9643C61.4168 40.4309 61.2038 39.9196 60.825 39.5441L53.3807 32.1631C52.8074 31.5947 51.9485 31.4267 51.2032 31.7373C50.458 32.0478 49.9725 32.776 49.9725 33.5834V37.1191H38.8937C37.7891 37.1191 36.8937 38.0145 36.8937 39.1191V42.8096C36.8937 43.9141 37.7891 44.8096 38.8937 44.8096H49.9725V48.3453C49.9725 49.1526 50.458 49.8808 51.2032 50.1914C51.9485 50.502 52.8074 50.334 53.3807 49.7655L60.825 42.3846Z"
        fill="#11161A"
        stroke="white"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9083_20263">
        <rect width="62" height="62" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const CalendarIcon = props => (
  <SvgIcon width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3333 1.99996H11.6666V0.666626H10.3333V1.99996H3.66659V0.666626H2.33325V1.99996H1.66659C0.933252 1.99996 0.333252 2.59996 0.333252 3.33329V14C0.333252 14.7333 0.933252 15.3333 1.66659 15.3333H12.3333C13.0666 15.3333 13.6666 14.7333 13.6666 14V3.33329C13.6666 2.59996 13.0666 1.99996 12.3333 1.99996ZM12.3333 14H1.66659V6.66663H12.3333V14ZM1.66659 5.33329H12.3333V3.33329H1.66659V5.33329Z"
      fill="black"
      fill-opacity="0.65"
    />
  </SvgIcon>
);

export const FilterIcon = props => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2.66667V4H0L0 2.66667H1.33333V0H2.66667L2.66667 2.66667H4ZM2.66667 12H1.33333L1.33333 5.33333H2.66667L2.66667 12ZM10.6667 12H9.33333V8H10.6667V12ZM12 5.33333H10.6667V0H9.33333V5.33333H8V6.66667H12V5.33333ZM5.33333 9.33333H4V8H8V9.33333H6.66667V12H5.33333V9.33333ZM6.66667 0H5.33333L5.33333 6.66667H6.66667L6.66667 0Z"
      fill="black"
    />
  </SvgIcon>
);

export const InformationIcon = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
      fill="black"
      fill-opacity="0.65"
    />
  </svg>
);

export const ChartInfoFirstInRankIcon = props => (
  <svg width="24" height="5" viewBox="0 0 24 5" fill="none" {...props}>
    <path
      d="M2 2.5L22 2.5"
      stroke="#00DDB0"
      strokeWidth="4"
      stroke-linecap="round"
      stroke-dasharray="6 2"
    />
  </svg>
);

export const ChartInfoLastInRankIcon = props => (
  <svg width="24" height="5" viewBox="0 0 24 5" fill="none" {...props}>
    <path
      d="M2 2.5L22 2.5"
      stroke="#F96282"
      strokeWidth="4"
      stroke-linecap="round"
      stroke-dasharray="6 2"
    />
  </svg>
);

export const ChartInfoPositionInRankIcon = props => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <rect y="0.5" width="16" height="16" rx="8" fill="#11161A" />
    <path
      d="M7.80966 12.5994C7.46165 12.5971 7.12192 12.5367 6.79048 12.4183C6.45904 12.2976 6.16075 12.1023 5.8956 11.8324C5.63045 11.5601 5.41974 11.1991 5.26349 10.7493C5.10724 10.2971 5.0303 9.73722 5.03267 9.0696C5.03267 8.44697 5.09896 7.89181 5.23153 7.40412C5.36411 6.91643 5.55469 6.5045 5.80327 6.16832C6.05185 5.82978 6.35133 5.57173 6.7017 5.39418C7.05445 5.21662 7.44863 5.12784 7.88423 5.12784C8.34115 5.12784 8.74598 5.2178 9.09872 5.39773C9.45384 5.57765 9.74029 5.82386 9.9581 6.13636C10.1759 6.4465 10.3108 6.79688 10.3629 7.1875H9.06676C9.00047 6.90814 8.86435 6.68561 8.65838 6.51989C8.45478 6.3518 8.19673 6.26776 7.88423 6.26776C7.37997 6.26776 6.99171 6.48674 6.71946 6.92472C6.44957 7.36269 6.31345 7.96402 6.31108 8.72869H6.3608C6.4768 8.52036 6.62713 8.34162 6.81179 8.19247C6.99645 8.04332 7.20478 7.9285 7.43679 7.84801C7.67116 7.76515 7.91856 7.72372 8.17898 7.72372C8.60511 7.72372 8.98745 7.82552 9.32599 8.02912C9.6669 8.23272 9.93679 8.51326 10.1357 8.87074C10.3345 9.22585 10.4328 9.63305 10.4304 10.0923C10.4328 10.5705 10.3239 11.0002 10.1037 11.3814C9.88352 11.7602 9.57694 12.0585 9.18395 12.2763C8.79096 12.4941 8.33286 12.6018 7.80966 12.5994ZM7.80256 11.5341C8.06061 11.5341 8.29143 11.4714 8.49503 11.3459C8.69863 11.2204 8.85961 11.0511 8.97798 10.8381C9.09635 10.625 9.15436 10.3859 9.15199 10.1207C9.15436 9.86032 9.09754 9.62476 8.98153 9.41406C8.8679 9.20336 8.71046 9.03646 8.50923 8.91335C8.308 8.79025 8.07836 8.72869 7.82031 8.72869C7.62855 8.72869 7.44981 8.76539 7.28409 8.83878C7.11837 8.91217 6.97396 9.01397 6.85085 9.14418C6.72775 9.27202 6.63068 9.42116 6.55966 9.59162C6.491 9.75971 6.45549 9.93963 6.45312 10.1314C6.45549 10.3847 6.51468 10.6179 6.63068 10.831C6.74669 11.044 6.90649 11.2145 7.11009 11.3423C7.31368 11.4702 7.54451 11.5341 7.80256 11.5341Z"
      fill="white"
    />
  </svg>
);

export const DescendingSortIcon = props => (
  <svg width="23" height="17" viewBox="0 0 13 7" fill="none" {...props}>
    <g clipPath="url(#clip0_58_93)">
      <path
        d="M5.54166 4.375L5.13041 3.96375L3.79166 5.29958V0.583328H3.20833V5.29958L1.86958 3.96083L1.45833 4.375L3.5 6.41666L5.54166 4.375Z"
        fill="#6C50FF"
      />
    </g>
    <g clipPath="url(#clip1_58_93)">
      <path
        d="M6.875 5.25H8.625V4.66667H6.875V5.25ZM6.875 1.75V2.33333H12.125V1.75H6.875ZM6.875 3.79167H10.375V3.20833H6.875V3.79167Z"
        fill="#6C50FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_58_93">
        <rect width="7" height="7" fill="white" />
      </clipPath>
      <clipPath id="clip1_58_93">
        <rect width="7" height="7" fill="white" transform="translate(6)" />
      </clipPath>
    </defs>
  </svg>
);

export const AscendingSortIcon = props => (
  <svg width="23" height="17" viewBox="0 0 13 7" fill="none" {...props}>
    <g clipPath="url(#clip0_58_93)">
      <path
        d="M1.45832 2.625L1.86957 3.03625L3.20832 1.70042L3.20832 6.41667H3.79166V1.70042L5.13041 3.03917L5.54166 2.625L3.49999 0.583338L1.45832 2.625Z"
        fill="#6C50FF"
      />
    </g>
    <g clipPath="url(#clip1_58_93)">
      <path
        d="M6.87502 5.25H8.62502V4.66667H6.87502V5.25ZM6.87502 1.75V2.33333H12.125V1.75H6.87502ZM6.87502 3.79167H10.375V3.20833H6.87502V3.79167Z"
        fill="#6C50FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_58_93">
        <rect
          width="7"
          height="7"
          fill="white"
          transform="matrix(-1 0 0 -1 6.99998 7)"
        />
      </clipPath>
      <clipPath id="clip1_58_93">
        <rect
          width="7"
          height="7"
          fill="white"
          transform="translate(6.00002)"
        />
      </clipPath>
    </defs>
  </svg>
);

Arrow.propTypes = { ...SvgIcon.propTypes };
CollapseButtonIcon.propTypes = { ...SvgIcon.propTypes };
KPIsIcon.propTypes = { ...SvgIcon.propTypes };
ClockIcon.propTypes = { ...SvgIcon.propTypes };
ProductsIcon.propTypes = { ...SvgIcon.propTypes };
CompareIcon.propTypes = { ...SvgIcon.propTypes };
Avatar.propTypes = { ...SvgIcon.propTypes };
Close.propTypes = { ...SvgIcon.propTypes };
Vector.propTypes = { ...SvgIcon.propTypes };
Sort.propTypes = { ...SvgIcon.propTypes };
ArrowDownIcon.propTypes = { ...SvgIcon.propTypes };
DistrictViewIcon.propTypes = { ...SvgIcon.propTypes };
DistrictViewIconLight.propTypes = { ...SvgIcon.propTypes };
StoreViewIcon.propTypes = { ...SvgIcon.propTypes };
StoreViewIconLight.propTypes = { ...SvgIcon.propTypes };
RankingIcon.propTypes = { ...SvgIcon.propTypes };
CalendarIcon.propTypes = { ...SvgIcon.propTypes };
FilterIcon.propTypes = { ...SvgIcon.propTypes };
InformationIcon.propTypes = { ...SvgIcon.propTypes };
ChartInfoFirstInRankIcon.propTypes = { ...SvgIcon.propTypes };
ChartInfoLastInRankIcon.propTypes = { ...SvgIcon.propTypes };
ChartInfoPositionInRankIcon.propTypes = { ...SvgIcon.propTypes };
DescendingSortIcon.propTypes = { ...SvgIcon.propTypes };
AscendingSortIcon.propTypes = { ...SvgIcon.propTypes };
