import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { withRouter, useHistory } from 'react-router-dom';

import { makeStyles, CardContent } from '@material-ui/core';

import { Select, SelectDrawer } from '../components/Select';

import { ButtonTabs, ButtonTab } from '../components/ButtonTabs';
import PositiveNegativeBarChart from '../components/Charts/recharts/PositiveNegativeBarChart';
import StorePositiveNegativeBarChart from '../components/Charts/recharts/StorePositiveNegativeBarChart';
import SecondaryBar from '../components/AppBar/SecondaryBar';

import { CardGrid, Card } from '../components/Cards';
import { usePreferencesManager } from '../data/user-preferences';

import { Text2 } from '../typography';

const MockStoreData = {
  storeInfo: [
    {
      storeId: 'store/0000000484',
      name: '484 - ORLANDO INTERNATIONAL',
      chartData: {
        netSales: 1,
        traffic: -0.5,
        conversion: 2,
        upt: -0.5,
      },
    },
    {
      storeId: 'store/0000000133',
      name: '133 - FASHION ISLAND NEWPORT',
      chartData: {
        netSales: 2,
        traffic: 1,
        conversion: 1,
        upt: 0.5,
      },
    },
    {
      storeId: 'store/0000007110',
      name: '7214-RIOCAN DURHAM CENTRE',
      chartData: {
        netSales: -0.75,
        traffic: -0.25,
        conversion: 2,
        upt: 4,
      },
    },
    {
      storeId: 'store/0000000230',
      name: '230 - LIGHTHOUSE',
      chartData: {
        netSales: 2.5,
        traffic: -0.2,
        conversion: 0.5,
        upt: 0.5,
      },
    },
    {
      storeId: 'store/0000000294',
      name: '294 – FREEPORT',
      chartData: {
        netSales: 0.6,
        traffic: 0.25,
        conversion: 2,
        upt: 2,
      },
    },
    {
      storeId: 'store/0000000282',
      name: '282 - FASHION DISTRICT OF PHILADELPHIA',
      chartData: {
        netSales: 0.5,
        traffic: -0.5,
        conversion: -0.5,
        upt: 1.5,
      },
    },
    {
      storeId: 'store/0000000416',
      name: '416 - PARK CITY',
      chartData: {
        netSales: 0.3,
        traffic: 0.5,
        conversion: 1.5,
        upt: 1,
      },
    },
    {
      storeId: 'store/0000000265',
      name: '265 - THE OUTLETS AT LITTLE ROCK',
      chartData: {
        netSales: -0.7,
        traffic: -0.5,
        conversion: -1.2,
        upt: -0.5,
      },
    },
    {
      storeId: 'store/0000006103',
      name: '6103 - STONERIDGE MCW',
      chartData: {
        netSales: -0.5,
        traffic: -1.25,
        conversion: -0.75,
        upt: 0.5,
      },
    },
    {
      storeId: 'store/0000000443',
      name: '443 - WOODBURY',
      chartData: {
        netSales: 1.25,
        traffic: 1,
        conversion: 0.25,
        upt: 0.5,
      },
    },
    {
      storeId: 'store/0000000455',
      name: '455 - SEATTLE PREMIUM',
      chartData: {
        netSales: -0.75,
        traffic: -1,
        conversion: -1,
        upt: 0.25,
      },
    },
    {
      storeId: 'store/0000000129',
      name: '129 – CENTURY CITY',
      chartData: {
        netSales: 0.25,
        traffic: -0.25,
        conversion: 0.25,
        upt: 1.25,
      },
    },
  ],
};

const MockDistrictData = {
  netSales: 2,
  traffic: 8,
  conversion: -1,
  upt: 6,
};

const timePeriodsOptions = [
  { id: 'wtd', label: 'app.time_period_wtd' },
  { id: 'mtd', label: 'app.time_period_mtd' },
];

const SalesBuckets = [
  { index: 'previous_week', label: 'app.previous_week' },
  { index: 'same_week_ly', label: 'app.same_week_ly' },
];

const getInitialPeriod = (items, pm) => {
  if (!items) return null;
  return items.find(x => x.id === pm.get('districtTimePeriod')) || items[0];
};

const useStyles = makeStyles(theme => ({
  districtKPIContainer: {},
  pageHeading: {
    marginTop: 8,
    fontWeight: 400,
    fontSize: 10,
    textTransform: 'uppercase',
  },
  tabContainer: {
    margin: '0.75rem 0',
  },
  tabs: {
    fontSize: '1.3rem',
  },
  cardGrid: {
    marginBottom: '0.5rem',
    alignItems: 'stretch',
    '& .MuiCard-root': {
      borderRadius: 2,
      padding: '8px 10px',
      background: 'rgba(0, 0, 0, 0.04)',
      marginTop: 0,
    },
  },
  cardContent: {
    width: '100%',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5) + 2}px`,
    color: '#000',
    height: '120px',
  },
  smallCardContent: {
    width: '100%',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5) + 2}px`,
    color: '#000',
    height: '84px',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14px',
  },
  subHeading: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '12px',
  },
  timePeriodDrawerDone: {
    color: '#2C04FF',
    opacity: 0.7,
    fontWeight: 600,
    fontSize: 14,
  },
  translatedSelectedTimePeriod: {
    '& .MuiButtonBase-root': {
      minHeight: 40,
      paddingTop: 0,
    },
  },
}));

const DistrictKPIs = ({ onViewSwitchClick, setLocationTree }) => {
  const styles = useStyles();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const pm = usePreferencesManager();

  const [comparedTo, setComparedTo] = useState('previous_week');
  const [timePeriodsDrawer, setTimePeriodsDrawer] = useState(false);
  const [tempTimePeriod, setTempTimePeriod] = useState();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(() =>
    getInitialPeriod(timePeriodsOptions, pm)
  );

  const onSalesTypeChange = () => {};
  const onTimePeriodDrawerClose = () => {
    setTempTimePeriod();
    toggleTimePeriodDrawer();
  };
  const handleSelectedTimePeriod = item => {
    setTempTimePeriod(item);
    pm.set('districtTimePeriod', item.id);
  };

  const toggleTimePeriodDrawer = () => {
    setTimePeriodsDrawer(!timePeriodsDrawer);
  };

  const getTimePeriodTranslatedList = () =>
    timePeriodsOptions.map(option => {
      return {
        ...option,
        label: f({ id: option.label }),
      };
    });

  const handleStoreCardClick = storeId => {
    setLocationTree(storeId);
    history.push('/');
    onViewSwitchClick();
  };

  const getTranslatedSelectedTimePeriod = () => {
    const translatedTP = f({ id: `${selectedTimePeriod.label}` });
    const timePeriod = translatedTP.split('(')[0];
    return timePeriod;
  };

  return (
    <div className={styles.districtKPIContainer}>
      <SelectDrawer
        open={timePeriodsDrawer}
        title={f({ id: 'app.select_time_period' })}
        onClose={onTimePeriodDrawerClose}
        closeContent={
          <div
            className={styles.timePeriodDrawerDone}
            onClick={() => {
              setSelectedTimePeriod(
                tempTimePeriod ? tempTimePeriod : selectedTimePeriod
              );
              onTimePeriodDrawerClose();
            }}
          >
            {f({ id: 'app.done' })}
          </div>
        }
      >
        <Select
          items={getTimePeriodTranslatedList()}
          selected={tempTimePeriod ? tempTimePeriod : selectedTimePeriod}
          onSelect={handleSelectedTimePeriod}
        />
      </SelectDrawer>
      <div className={styles.translatedSelectedTimePeriod}>
        <SecondaryBar
          text={getTranslatedSelectedTimePeriod()}
          onClick={toggleTimePeriodDrawer}
        />
      </div>
      <div className={styles.pageHeading}>Compare To</div>
      <ButtonTabs
        onChange={(_, val) => {
          setComparedTo(val);
          onSalesTypeChange(val);
        }}
        value={comparedTo}
        className={styles.tabContainer}
      >
        {SalesBuckets.map(({ index, label }) => (
          <ButtonTab
            className={styles.tabs}
            key={index}
            label={f({ id: `${label}` })}
            value={index}
          />
        ))}
      </ButtonTabs>
      <CardGrid
        justify="flex-start"
        itemXs={12}
        itemSm={12}
        itemMd={12}
        itemLg={12}
        className={styles.cardGrid}
      >
        <Card
          key={'kpi'}
          color="tertiary"
          justify="stretch"
          spacing={1}
          style={{ width: '100%', maxWidth: '100%' }}
        >
          <CardContent className={styles.cardContent}>
            <PositiveNegativeBarChart data={MockDistrictData} />
          </CardContent>
        </Card>
      </CardGrid>
      <CardGrid
        justify="flex-start"
        itemXs={6}
        itemSm={6}
        itemMd={6}
        itemLg={3}
        className={styles.cardGrid}
      >
        {MockStoreData.storeInfo.map(({ name, storeId, chartData }) => (
          <Card
            key={'kpi'}
            color="tertiary"
            justify="stretch"
            className={styles.cardCard}
            spacing={1}
          >
            <CardContent
              className={styles.smallCardContent}
              onClick={() => handleStoreCardClick(storeId)}
            >
              <Text2
                component="p"
                className={styles.title}
                style={{ fontWeight: 500 }}
              >
                <label className={styles.heading}>{name}</label>
                <label className={styles.subHeading}>{storeId}</label>
              </Text2>
              <StorePositiveNegativeBarChart chartData={chartData} />
            </CardContent>
          </Card>
        ))}
      </CardGrid>
    </div>
  );
};

DistrictKPIs.propTypes = {};

const DistrictKPIsRouter = withRouter(DistrictKPIs);

export default DistrictKPIsRouter;
