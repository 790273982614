import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  CardContent,
  CardActionArea,
  Collapse,
  Popover,
  useMediaQuery,
} from '@material-ui/core';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useIntl } from 'react-intl';

import Card from './Card';
import { Text2, Heading1, Note } from '../../typography';
import PerformancePill from '../PerformancePill';
import ClickIndicator from './ClickIndicator';
import { isNullOrUndefined } from '../../util/object';
import KPiGraphTabs from './KPiGraphTabs';
import { TrendsLineIcon } from '../Icons';
import { GTAG_EVENT } from '../../gtag';

const paddingTopBottom = 1.75;
const paddingLeftRight = 1.5; // as theme.spacing units
const useStyles = props => {
  return makeStyles(theme => ({
    cardRoot: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    popoverSize: {
      '& .MuiPopover-paper': {
        width: '110% !important',
        top: '155px !important',
      },
    },

    content: {
      minHeight: 116,
      boxSizing: 'border-box',
      display: 'inline-flex',
      flexDirection: 'column',
      padding: theme.spacing(paddingTopBottom, paddingLeftRight),
      width: '100%',
    },
    contentNumber: {
      // minHeight: 116,
      height: 84,
      justifyContent: 'space-between',
      // height: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    valueAndPills: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    pills: {
      display: 'flex',
      flexDirection: 'column',
      '& > :last-child': {
        marginTop: 2,
      },
    },
    pill: {
      borderRadius: 12,
      height: 24,
      marginLeft: 'auto',
      minWidth: 'unset',
    },
    label: {
      marginBottom: 6,
      display: 'flex',
      flexDirection: 'row-start',
    },
    clickIndicator: {
      float: 'right',
      top: -5,
      position: 'relative',
      marginLeft: 2,
      cursor: 'pointer',
    },
    chartIcon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(0.5),
      cursor: 'pointer',
    },
    arrowIcon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(-0.25),
      position: 'relative',
      cursor: 'pointer',
      float: 'right',
    },
    chart: {
      height: 320,
      width: '100%',
      paddingBottom: theme.spacing(1.5),
    },
    actionArea: {
      width: '30px',
      borderRadius: '100%',
    },
  }));
};

const PromotedMetricCard = ({
  onClick,
  metric,
  useLLY,
  style = {},
  color,
  spacing,
  moreDetails = false,
  useUsdAmount,
}) => {
  const classes = useStyles(style)();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isSmallScreen = useMediaQuery('(max-width:450px)');
  const { formatMessage: f } = useIntl();

  const {
    id,
    label,
    formattedValue,
    formattedLLYValue,
    dpy,
    dpp,
    value,
    value_lly,
    formattedTargetValue,
    formattedTrends,
    format,
  } = metric;
  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (expanded) {
      GTAG_EVENT({ view: 'trends_view', category: 'Trendlines', label: label });
    }
  };

  const handlePopupOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };
  const trendsLength = formattedTrends
    ? Object.keys(formattedTrends).length
    : 0;
  return (
    <Card color={color} spacing={spacing} className={classes.cardRoot}>
      <CardContent className={classes.content}>
        <div className={classes.contentNumber}>
          <div className={classes.label}>
            <Text2 style={{ width: '100%' }}>
              {moreDetails && trendsLength > 0 && (
                <>
                  <TrendsLineIcon
                    fontSize="large"
                    className={classes.chartIcon}
                    onClick={
                      isSmallScreen ? handlePopupOpen : handleExpandClick
                    }
                  />
                  {expanded && (
                    <ArrowUpwardIcon
                      className={classes.arrowIcon}
                      onClick={handleExpandClick}
                      fontSize="large"
                      color="inherit"
                    />
                  )}
                  {!expanded && (
                    <ArrowDownwardIcon
                      fontSize="large"
                      color="inherit"
                      className={classes.arrowIcon}
                      onClick={handleExpandClick}
                    />
                  )}
                </>
              )}
              {f({ id: `app.${id}` })}
            </Text2>
            {onClick && (
              <CardActionArea onClick={onClick} className={classes.actionArea}>
                <ClickIndicator
                  className={classes.clickIndicator}
                  onClick={onClick}
                />
              </CardActionArea>
            )}
          </div>
          <div className={classes.valueAndPills}>
            <div>
              {(!isNullOrUndefined(value) || !isNullOrUndefined(value_lly)) && (
                <Heading1 caps={false} style={{ fontSize: '3rem' }}>
                  {useLLY && formattedLLYValue
                    ? formattedLLYValue
                    : formattedValue}
                </Heading1>
              )}

              {!isNullOrUndefined(formattedTargetValue) && (
                <Note
                  gray
                  caps={false}
                  style={{ display: 'block', marginTop: 4 }}
                >
                  of {formattedTargetValue} plan
                </Note>
              )}
            </div>
            <div className={classes.pills}>
              {dpp && (
                <PerformancePill
                  classes={{ root: classes.pill }}
                  delta={dpp}
                  label={f({ id: 'app.vs_plan' })}
                  showArrow={false}
                />
              )}
              {dpy && (
                <PerformancePill
                  classes={{ root: classes.pill }}
                  delta={dpy}
                  label={useLLY ? 'vs PPY' : 'vs PY'}
                  showArrow={false}
                />
              )}
            </div>
          </div>
        </div>
        {isSmallScreen ? (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopupClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className={classes.popoverSize}
          >
            <KPiGraphTabs
              data={formattedTrends}
              dots={false}
              dataKeyA="value"
              dataKeyB="value_py"
              strokeA="#8884d8"
              strokeB="#D0D0D0"
              useLLY={useLLY}
              format={format}
              useUsdAmount={useUsdAmount}
            />
          </Popover>
        ) : (
          <Collapse
            in={trendsLength > 0 ? expanded : false}
            timeout="auto"
            unmountOnExit
          >
            <KPiGraphTabs
              data={formattedTrends}
              dots={false}
              dataKeyA="value"
              dataKeyB="value_py"
              strokeA="#8884d8"
              strokeB="#D0D0D0"
              useLLY={useLLY}
              format={format}
              useUsdAmount={useUsdAmount}
            />
          </Collapse>
        )}
      </CardContent>
    </Card>
  );
};

PromotedMetricCard.propTypes = {
  onClick: PropTypes.func,
  metric: PropTypes.object.isRequired,
};

export default PromotedMetricCard;
