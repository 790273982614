import { useData, getCompStores } from '../../api';
import { useHandleNotGeneratedPeriod } from './useHandleNotGeneratedPeriod';

export default function useCompStores(store_id) {
  const compStore = useData(getCompStores, { store_id }, 'comp_stores');

  let data = undefined;
  if (!compStore.loading && compStore.data) {
    const response = compStore.data;
    const { store_id } = response;
    data = store_id;
  }

  useHandleNotGeneratedPeriod({ response: compStore, status: 0 });

  return {
    data,
    loading: compStore.loading,
    error: compStore.error,
    refetch: () => {
      if (compStore.error) compStore.refetch();
    },
  };
}
