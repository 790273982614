import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { useSecurity, msalConfigScope } from '../../auth';
import { useConfig } from '../../config';
import { PageView } from '../../gtag';
import View from './view';
import LoadingBar from '../../components/LoadingBar';

const mapError = (authError, connectionError) => {
  if (connectionError) {
    return 'redirect_failed';
  }
  if (authError) {
    switch (authError.code) {
      case 'access_denied':
      case 'temporarily_unavailable':
        return authError.code;
      default:
        return 'generic';
    }
  }
  return;
};

const login = async instance => {
  return await instance.loginRedirect({
    scopes: [msalConfigScope],
  });
};

const Login = () => {
  const { instance, isAuthenticated, error: authError } = useSecurity();
  const { SPLASH_ENABLE: splashEnable } = useConfig();
  const { inProgress } = useMsal();
  // const { error: authError } = useMsalAuthentication();
  const [redirectError, setRedirectError] = useState();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (inProgress !== 'none') {
    return <LoadingBar />;
  }

  const errorType = mapError(authError, redirectError);

  let isSplashEnabled = false;
  // Empty string checked explicitly because '' always matches as a media query.
  if (typeof splashEnable === 'string' && splashEnable !== '') {
    const mq = window.matchMedia(splashEnable);
    isSplashEnabled = mq.matches;
  }

  return (
    <PageView>
      <View
        errorType={errorType}
        splash={isSplashEnabled}
        onClickLogin={e =>
          login(instance).catch(err => {
            console.log(err);
            setRedirectError(err);
            e.cancelLogin();
          })
        }
      />
    </PageView>
  );
};

export default Login;
