import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, withStyles } from '@material-ui/core';

import RankingChart from '../components/Charts/recharts/RankingChart';
import { ButtonTabs, ButtonTab } from '../components/ButtonTabs';
import { CalendarIcon, FilterIcon } from '../components/Icons';
import {
  StoreSelectDrawer,
  TimePeriodSelectDrawer,
  ChartInfoDrawer,
} from '../components/DistrictRankingDrawers';
import { Heading3 } from '../typography';

const PERIODLIST = [
  'day20230112',
  'day20230113',
  'day20230114',
  'day20230115',
  'day20230116',
  'day20230117',
  'day20230118',
];
const STORELIST = [
  '1234 - Forum Shops',
  '3322 - Anthem',
  '4325 - Scottdale Fashio...',
  '6560 - LV Premium No...',
  '3211 - Prism Outlet',
  '1122 - Laughlin',
  '1126 - Santa Fe',
  '4433 - LV Premium South',
  '4522 - Phoenix Westgate',
  '1234 - St George',
  '4522 - Phoenix Premium',
  '7622 - El Paso',
];

const SalesBuckets = [
  { index: 'net_sales', label: 'app.net_sales' },
  { index: 'traffic', label: 'app.traffic' },
  { index: 'conversion', label: 'app.conversion' },
  { index: 'upt', label: 'app.upt' },
];

const styles = theme => ({
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0',
  },
  headingIcon: {
    fontSize: 12,
    padding: '6px 10px',
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '50%',
  },
  headingTitle: {
    fontSize: '1.5rem',
  },
  tabContainer: {
    marginBottom: 12,
  },
});

const DistrictRanking = ({ classes }) => {
  const { formatMessage: f } = useIntl();
  const [comparedTo, setComparedTo] = useState('net_sales');
  const [storeSelectDrawer, setStoreSelectDrawer] = useState(false);
  const [timePeriodsDrawer, setTimePeriodsDrawer] = useState(false);
  const [chartInfoDrawer, setChartInfoDrawer] = useState(false);

  const graphData = {
    data: [],
    highestRank: '4522 - Phoenix Premium',
    lowestRank: '1122 - Laughlin',
  };
  const rand = 3000;
  for (const period in PERIODLIST) {
    const dataItem = {
      period: PERIODLIST[period],
      ranking: {},
    };
    for (const store in STORELIST) {
      dataItem[STORELIST[store]] = Math.random() * (rand + 50) + 100;
      dataItem.ranking[STORELIST[store]] = Math.floor(
        Math.random() * (12 - 1 + 1) + 1
      );
    }
    graphData.data.push(dataItem);
  }
  const onDataTypeChange = () => {};
  const onCalendarIconClick = () => {
    setTimePeriodsDrawer(!timePeriodsDrawer);
  };
  const onFilterIconClick = () => {
    setStoreSelectDrawer(!storeSelectDrawer);
  };
  const onChartInfoIconClick = () => {
    setChartInfoDrawer(!chartInfoDrawer);
  };

  const { highestRank, lowestRank, data } = graphData;

  return (
    <div>
      <div className={classes.heading}>
        <StoreSelectDrawer
          storeSelectDrawer={storeSelectDrawer}
          onFilterIconClick={onFilterIconClick}
        />
        <TimePeriodSelectDrawer
          timePeriodsDrawer={timePeriodsDrawer}
          onCalendarIconClick={onCalendarIconClick}
        />
        <ChartInfoDrawer
          chartInfoDrawer={chartInfoDrawer}
          onChartInfoIconClick={onChartInfoIconClick}
        />
        <IconButton
          classes={{ root: classes.headingIcon }}
          onClick={onFilterIconClick}
        >
          <FilterIcon />
        </IconButton>
        <Heading3
          component="h6"
          align="center"
          className={classes.headingTitle}
        >
          {f({ id: 'app.all_stores' })}
        </Heading3>

        <IconButton
          classes={{ root: classes.headingIcon }}
          onClick={onCalendarIconClick}
        >
          <CalendarIcon />
        </IconButton>
      </div>

      <ButtonTabs
        onChange={(_, val) => {
          setComparedTo(val);
          onDataTypeChange(val);
        }}
        value={comparedTo}
        className={classes.tabContainer}
      >
        {SalesBuckets.map(({ index, label }) => (
          <ButtonTab
            className={classes.tabs}
            key={index}
            label={f({ id: `${label}` })}
            value={index}
          />
        ))}
      </ButtonTabs>
      <RankingChart
        highestRank={highestRank}
        lowestRank={lowestRank}
        data={data}
        storeList={STORELIST}
        onChartInfoIconClick={onChartInfoIconClick}
      />
    </div>
  );
};

export default withStyles(styles, { name: 'LriDistrictRanking' })(
  DistrictRanking
);
