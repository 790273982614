import React from 'react';
import PropTypes from 'prop-types';
import {
    AccordionDetails as MuiAccordionDetails,
    withStyles,
    withTheme,
} from '@material-ui/core';

const styles = theme => ({
    root: {
        flexDirection: 'column',
        padding: 0,
        backgroundColor: '#f1f2fd'
    }
});

const AccordionDetails = ({ classes, children }) => (
    <MuiAccordionDetails className={classes.root}>
        {children}
    </MuiAccordionDetails>
);

AccordionDetails.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withTheme(withStyles(styles, { name: 'LriAccordionDetails' })(AccordionDetails));
