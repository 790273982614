import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { Select, SelectDrawer } from './Select';
import {
  ChartInfoFirstInRankIcon,
  ChartInfoLastInRankIcon,
  ChartInfoPositionInRankIcon,
} from './Icons';
import { usePreferencesManager } from '../data/user-preferences';

const timePeriodsOptions = [
  { id: 'pd', label: 'app.pd' },
  { id: 'wtd', label: 'app.wtd' },
  { id: 'mtd', label: 'app.mtd' },
  { id: 'qtd', label: 'app.qtd' },
  { id: 'ytd', label: 'app.ytd' },
];

const storeTypeOptions = [
  { id: 'mainline', label: 'app.mainline_stores' },
  { id: 'outlet', label: 'app.outlet_stores' },
  { id: 'all', label: 'app.all_stores' },
];

const getInitialValue = (items, pm, storedKey) => {
  if (!items) return null;
  return items.find(x => x.id === pm.get(storedKey)) || items[0];
};
const useStyles = makeStyles(theme => ({
  drawerDone: {
    color: '#2C04FF',
    opacity: 0.7,
    fontWeight: 600,
    fontSize: 14,
  },
  infoContainer: {
    padding: '16px 0 24px 15px',
  },
  chartInfo: {
    marginBottom: 16,
    display: 'flex',
  },
  labels: {
    marginRight: 12,
    width: 20,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 13,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.65)',
  },
}));

export const StoreSelectDrawer = ({ storeSelectDrawer, onFilterIconClick }) => {
  const styles = useStyles();

  const { formatMessage: f } = useIntl();
  const pm = usePreferencesManager();

  const [tempTimePeriod, setTempTimePeriod] = useState();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(() =>
    getInitialValue(storeTypeOptions, pm, 'districtRankingStoreType')
  );

  const toggleTimePeriodDrawer = () => {
    onFilterIconClick();
  };
  const onTimePeriodDrawerClose = () => {
    setTempTimePeriod();
    toggleTimePeriodDrawer();
  };

  const handleSelectedTimePeriod = item => {
    setTempTimePeriod(item);
    pm.set('districtRankingStoreType', item.id);
  };

  const getTimePeriodTranslatedList = () =>
    storeTypeOptions.map(option => {
      return {
        ...option,
        label: f({ id: option.label }),
      };
    });

  return (
    <SelectDrawer
      open={storeSelectDrawer}
      title={f({ id: 'app.select_store_type' })}
      onClose={onTimePeriodDrawerClose}
      closeContent={
        <div
          className={styles.drawerDone}
          onClick={() => {
            setSelectedTimePeriod(
              tempTimePeriod ? tempTimePeriod : selectedTimePeriod
            );
            onTimePeriodDrawerClose();
          }}
        >
          {f({ id: 'app.done' })}
        </div>
      }
    >
      <Select
        items={getTimePeriodTranslatedList()}
        selected={tempTimePeriod ? tempTimePeriod : selectedTimePeriod}
        onSelect={handleSelectedTimePeriod}
      />
    </SelectDrawer>
  );
};

export const TimePeriodSelectDrawer = ({
  timePeriodsDrawer,
  onCalendarIconClick,
}) => {
  const styles = useStyles();

  const { formatMessage: f } = useIntl();
  const pm = usePreferencesManager();

  const [tempTimePeriod, setTempTimePeriod] = useState();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(() =>
    getInitialValue(timePeriodsOptions, pm, 'districtRankingTimePeriod')
  );

  const toggleTimePeriodDrawer = () => {
    onCalendarIconClick();
  };
  const onTimePeriodDrawerClose = () => {
    setTempTimePeriod();
    toggleTimePeriodDrawer();
  };

  const handleSelectedTimePeriod = item => {
    setTempTimePeriod(item);
    pm.set('districtRankingTimePeriod', item.id);
  };

  const getTimePeriodTranslatedList = () =>
    timePeriodsOptions.map(option => {
      return {
        ...option,
        label: f({ id: option.label }),
      };
    });

  return (
    <SelectDrawer
      open={timePeriodsDrawer}
      title={f({ id: 'app.select_time_period' })}
      onClose={onTimePeriodDrawerClose}
      closeContent={
        <div
          className={styles.drawerDone}
          onClick={() => {
            setSelectedTimePeriod(
              tempTimePeriod ? tempTimePeriod : selectedTimePeriod
            );
            onTimePeriodDrawerClose();
          }}
        >
          {f({ id: 'app.done' })}
        </div>
      }
    >
      <Select
        items={getTimePeriodTranslatedList()}
        selected={tempTimePeriod ? tempTimePeriod : selectedTimePeriod}
        onSelect={handleSelectedTimePeriod}
      />
    </SelectDrawer>
  );
};

export const ChartInfoDrawer = ({ chartInfoDrawer, onChartInfoIconClick }) => {
  const styles = useStyles();

  const { formatMessage: f } = useIntl();

  const onTimePeriodDrawerClose = () => {
    onChartInfoIconClick();
  };

  return (
    <SelectDrawer
      open={chartInfoDrawer}
      title={f({ id: 'app.chart_info' })}
      onClose={onTimePeriodDrawerClose}
      closeContent={
        <div className={styles.drawerDone} onClick={onTimePeriodDrawerClose}>
          {f({ id: 'app.ok' })}
        </div>
      }
    >
      <div className={styles.infoContainer}>
        <div className={styles.chartInfo}>
          <label className={styles.labels}>
            <ChartInfoFirstInRankIcon />
          </label>
          <label className={styles.text}>
            {f({ id: 'app.first_in_the_ranking' })}
          </label>
        </div>
        <div className={styles.chartInfo}>
          <label className={styles.labels}>
            <ChartInfoLastInRankIcon />
          </label>
          <label className={styles.text}>
            {f({ id: 'app.last_in_the_ranking' })}
          </label>
        </div>
        <div className={styles.chartInfo}>
          <label className={styles.labels}>
            <ChartInfoPositionInRankIcon />
          </label>
          <label className={styles.text}>
            {f({ id: 'app.position_in_the_ranking' })}
          </label>
        </div>
      </div>
    </SelectDrawer>
  );
};
