import React from 'react';
import PropTypes from 'prop-types';
import {
    AccordionSummary as MuiAccordionSummary,
    withStyles,
    withTheme,
} from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: 'rgb(128 128 128 / 16%)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        '&.Mui-expanded': {
            minHeight: 26,
        },
        '& .MuiAccordionSummary-content': {
            margin: '12px 0'
        }
    }
});

const AccordionSummary = ({ classes, children, ...props }) => (
    <MuiAccordionSummary className={classes.root} {...props}>
        {children}
    </MuiAccordionSummary>
);

AccordionSummary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withTheme(withStyles(styles, { name: 'LriAccordionSummary' })(AccordionSummary));
