import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Drawer } from '@material-ui/core';

import { useSecurity } from '../../auth';
import { MainMenu, MainMenuOption } from '../../components/MainMenu';
import { GTAG_EVENT } from '../../gtag';
import { noop } from '../../util/function';
import { usePreferencesManager } from '../user-preferences';

const convertOldSettings = pm => {
  const oldSetting = pm.get('currency');
  pm.set('useUsdAmount', oldSetting === 'USD');
  pm.delete('currency');
};

const getInitialState = pm => {
  convertOldSettings(pm);
  let v1 = pm.get('useUsdAmount');
  let v2 = pm.get('includeTaxes');
  let v3 = pm.get('useLLY');
  let v4 = pm.get('language');
  return {
    useUsdAmount: v1 !== undefined ? Boolean(v1) : false,
    includeTaxes: v2 !== undefined ? Boolean(v2) : false,
    useLLY: v3 !== undefined ? Boolean(v3) : false,
    language: v4 !== undefined ? v4 : 'en',
  };
};

const UserShell = ({ children, history, onClose = noop }) => {
  const pm = usePreferencesManager();
  const [settings, setSettings] = useState(getInitialState(pm));
  const { isAuthenticated, userName, instance } = useSecurity();
  const [drawer, setDrawer] = useState(false);
  const { formatMessage: f } = useIntl();
  const toggleSelect = () => {
    setDrawer(true);
  };

  const handleClickLogOut = async () => {
    GTAG_EVENT({ view: 'logout' });
    await instance.logoutRedirect();
  };

  const handleChange = (name, value) => {
    pm.set(name, value);
    setSettings({
      ...settings,
      [name]: value,
    });
    GTAG_EVENT({
      view: 'setting_change',
      category: name,
      label: value,
    });
  };

  const handleKPIDefinitionClick = () => {
    history.push('/kpi-definition');
    setDrawer(false);
  };

  const languageChange = (name, value) => {
    handleChange(name, value);
    window.location.reload();
  };

  return (
    <>
      {children(settings, toggleSelect)}
      <Drawer
        anchor="right"
        open={drawer}
        onClose={_ => {
          onClose();
          setDrawer(false);
        }}
      >
        <MainMenu
          userName={userName}
          settings={settings}
          showLogOut={isAuthenticated}
          onClickLogOut={handleClickLogOut}
        >
          <MainMenuOption
            label={f({ id: 'app.currencies_usd' })}
            name="useUsdAmount"
            checked={settings.useUsdAmount}
            onChange={handleChange}
          />
          <MainMenuOption
            label={f({ id: 'app.include_taxes' })}
            name="includeTaxes"
            checked={settings.includeTaxes}
            onChange={handleChange}
          />
          <MainMenuOption
            label={f({ id: 'app.use_lly' })}
            name="useLLY"
            checked={settings.useLLY}
            onChange={handleChange}
          />
          <MainMenuOption
            label={f({ id: 'app.kpi-definition' })}
            switchComponent={false}
            onClickComponent={true}
            onChange={handleKPIDefinitionClick}
          />
          <MainMenuOption
            label={f({ id: 'app.language' })}
            name="language"
            items={[
              { id: 'en', label: 'English' },
              { id: 'es', label: 'Español' },
            ]}
            checked={settings.language}
            onChange={languageChange}
            switchComponent={false}
            dropdownComponent
          />
        </MainMenu>
      </Drawer>
    </>
  );
};

UserShell.propTypes = {
  onClose: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(UserShell);
