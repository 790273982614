import React from 'react';
import PropTypes from 'prop-types';
import {
    Accordion as MuiAccordion,
    withStyles,
    withTheme,
} from '@material-ui/core';
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    root: {

    },
});

const Accordion = ({ classes, children, summaryChildren, ...props }) => (
    <MuiAccordion className={classes.root} {...props}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            {summaryChildren}
        </AccordionSummary>
        <AccordionDetails>
            {children}
        </AccordionDetails>
    </MuiAccordion>
);

Accordion.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withTheme(withStyles(styles, { name: 'LriAccordion' })(Accordion));
