import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { YSeriesConfig, TrafficConversionChartDetails } from './constants';

const styles = () => ({
  container: {
    '& .recharts-legend-wrapper': {
      '& li.recharts-legend-item:last-child': {
        marginRight: 'auto !important',
      },
      '& .recharts-legend-item-text': {
        verticalAlign: 'middle',
      },
    },
  },
});

const createSeries = (trends, xAxis, yAxisKeys) => {
  const data = [];
  const ySeriesData = {};

  xAxis.forEach((tick, index) => {
    yAxisKeys.forEach(key => {
      if (trends[tick] === undefined) {
        trends[tick] = {
          [key]: 0,
        };
      }
      let yAxisKeyValue = trends[tick][key];
      if (yAxisKeyValue === undefined) {
        yAxisKeyValue = 0;
      }
      ySeriesData[key] = YSeriesConfig[key].format
        ? YSeriesConfig[key].format(yAxisKeyValue)
        : yAxisKeyValue;
    });

    data.push({
      hours: tick,
      index,
      ...ySeriesData,
    });
  });

  return data;
};

const useStyles = makeStyles(
  () => ({
    customizeTooltip: {
      backgroundColor: '#080c94',
      opacity: 0.95,
      padding: 16,
      borderRadius: 10,
      borderColor: '#fff',
    },
    tooltipHeader: {
      color: '#fff',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      paddingBottom: 10,
      borderBottom: '1px solid #fff',
      marginBottom: 10,
    },
    payloadContent: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
  }),
  { name: 'CustomizedTooltip' }
);

const CustomizedTooltip = ({ label, payload, active }) => {
  const styles = useStyles();

  if (!active || !label || payload?.length === 0 || payload === null)
    return null;

  return (
    <div className={styles.customizeTooltip}>
      {<div className={styles.tooltipHeader}>Hours: {label}</div>}
      {payload.map(({ name, value, color, dataKey }) => {
        return (
          <div key={name} style={{ color }} className={styles.payloadContent}>
            {`${name}: ${value.toFixed(2)} ${YSeriesConfig[dataKey].unit ? YSeriesConfig[dataKey].unit : ''
              }`}
          </div>
        );
      })}
    </div>
  );
};

const TrafficConversionChart = ({ trends, classes }) => {
  const [chartData, setChartData] = useState(undefined);

  useEffect(() => {
    const tempTrends = trends;
    delete tempTrends['metricId'];
    formattingInChartData(tempTrends);
  }, [trends]);

  const formattingInChartData = trends => {
    // const xAxis = Object.keys(trends);
    const xAxis = [
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
    ];
    const data = createSeries(trends, xAxis, [
      'TRAFFIC',
      'CONVERSION_PERCENTAGE',
    ]);
    setChartData(data);
  };

  return (
    <ResponsiveContainer width="100%" className={classes.container}>
      <ComposedChart data={chartData}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis
          dataKey="hours"
          axisLine={{ stroke: '#f5f5f5', strokeWidth: 2 }}
        />
        <Legend
          verticalAlign="bottom"
          align="center"
          height={36}
          wrapperStyle={{ height: 16 }}
        />
        <Bar
          dataKey={TrafficConversionChartDetails.traffic.datakey}
          barSize={'20%'}
          fill={TrafficConversionChartDetails.traffic.color}
          stroke="#FFFFFF"
          strokeWidth="1"
          fillOpacity="0.4"
          yAxisId="left"
          legendType="rect"
          name={TrafficConversionChartDetails.traffic.name}
        />
        <Line
          dot={{
            stroke: `${TrafficConversionChartDetails.conversion.color}`,
            fill: `${TrafficConversionChartDetails.conversion.color}`,
            strokeWidth: 2,
          }}
          activeDot={{
            stroke: `${TrafficConversionChartDetails.conversion.color}`,
          }}
          strokeWidth={3}
          strokeLinecap="round"
          type="monotone"
          dataKey={TrafficConversionChartDetails.conversion.datakey}
          stroke={TrafficConversionChartDetails.conversion.color}
          yAxisId="right"
          legendType="circle"
          name={TrafficConversionChartDetails.conversion.name}
        />
        <YAxis
          tickLine={false}
          yAxisId="left"
          stroke={TrafficConversionChartDetails.traffic.color}
          label={{
            value: `${TrafficConversionChartDetails.traffic.name}`,
            angle: -90,
            style: {
              stroke: `${TrafficConversionChartDetails.traffic.color}`,
              strokeOpacity: 0.7,
              fontSize: 10,
              fontWeight: 100,
              letterSpacing: 1.5,
              transformOrigin: 'left',
              transform: 'rotate(-90deg) translateX(-2%) translateY(18%)',
            },
          }}
          axisLine={false}
        />
        <YAxis
          tickLine={false}
          yAxisId="right"
          orientation="right"
          stroke={TrafficConversionChartDetails.conversion.color}
          label={{
            value: `${TrafficConversionChartDetails.conversion.name}`,
            angle: -180,
            style: {
              stroke: `${TrafficConversionChartDetails.conversion.color}`,
              fontSize: 10,
              fontWeight: 100,
              letterSpacing: 1.5,
              transformOrigin: 'right',
              transform: 'rotate(90deg) translateX(2.25%) translateY(15%)',
            },
          }}
          axisLine={false}
          unit="%"
        />
        <Tooltip
          cursor={false}
          content={<CustomizedTooltip chartType="traffic-conversion-chart" />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

TrafficConversionChart.propTypes = {
  classes: PropTypes.object.isRequired,
  trends: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrafficConversionChart);
