import React from 'react';
import { withStyles } from '@material-ui/core';
import PositiveNegativeBarChart from './PositiveNegativeBarChart';

const styles = {};

const StorePositiveNegativeBarChart = ({ classes, chartData }) => {
  return (
    <div style={{ height: 64 }}>
      <PositiveNegativeBarChart smallChart={true} data={chartData} />
    </div>
  );
};

export default withStyles(styles, { name: 'StorePositiveNegativeBarChart' })(
  StorePositiveNegativeBarChart
);
