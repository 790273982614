import React, { createContext, useContext } from 'react';
import { useSecurity } from '../../auth';

const PreferencesContext = createContext();
PreferencesContext.displayName = 'Preferences';

export const USER_PREFERENCE_PREFIX = 'ri_';
/**
 * Provides an API for loading, reading and persisting user preferences.
 */
export class PreferencesManager {
  /**
   * @param {String} prefix
   */
  constructor(prefix) {
    this.prefix = USER_PREFERENCE_PREFIX;
    if (prefix) this.prefix += `${prefix}_`;
    this.preferences = {};
  }

  load() {
    this.preferences = Object.entries(localStorage)
      .filter(([k, v]) => k.startsWith(this.prefix))
      .reduce((p, [k, v]) => ({ ...p, [k]: v }), {});
  }

  /**
   * @param {String|number} key
   * @returns {String|Boolean}
   */
  get(key) {
    let value = this.preferences[this.prefix + key];
    if (value === 'false' || value === 'true') {
      value = value === 'true';
    }

    return value;
  }

  /**
   * @param {String|number} key
   * @param {String} value
   */
  set(key, value) {
    const fullKey = this.prefix + key;
    localStorage.setItem(fullKey, value);
    this.preferences[fullKey] = value;
  }

  delete(key) {
    const fullKey = this.prefix + key;
    localStorage.removeItem(fullKey);
    delete this.preferences[fullKey];
  }

  clear() {
    localStorage.clear();
  }
}

/**
 * Provides the passed in object of `PreferencesManager` type to the `usePreferencesManager` hook.
 *
 * @param {PreferencesManager} [manager]
 * @returns {PreferencesManager}
 */

export const PreferencesProvider = ({ children }) => {
  const { userId } = useSecurity();

  const pm = new PreferencesManager(userId);
  pm.load();

  return (
    <PreferencesContext.Provider value={pm}>
      {children}
    </PreferencesContext.Provider>
  );
};

/**
 * The hook exposes the object of type `PreferencesManager`.
 * It does **not** trigger a re-render when a _preference_ value is changed.
 * To achieve reactivity combine it with `useState` and optionally with `useContext`.
 * @returns {PreferencesManager} on object to access and set the persisted options
 */
export const usePreferencesManager = () => {
  const manager = useContext(PreferencesContext);
  return manager;
};
