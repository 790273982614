import React from 'react';
import { withStyles, Dialog, DialogTitle } from '@material-ui/core';
import { DistrictViewIconLight, StoreViewIconLight } from './Icons';
import PropTypes from 'prop-types';

const styles = () => ({
  paper: {
    textAlign: 'center',
    '& .MuiPaper-rounded': {
      borderRadius: 16,
    },
  },
  label: {
    display: 'block',
    fontWeight: 400,
    color: '#11161A',
  },
});

const ViewChangeLoader = ({ classes, open, handleClose = () => {}, view }) => (
  <Dialog onClose={handleClose} open={open} className={classes.paper}>
    {view === 'store' && (
      <DialogTitle>
        <StoreViewIconLight style={{ fontSize: '6.2rem' }} />
        <label className={classes.label}>Switching to Store view</label>
      </DialogTitle>
    )}
    {view === 'district' && (
      <DialogTitle>
        <DistrictViewIconLight style={{ fontSize: '6.2rem' }} />
        <label className={classes.label}>Switching to Distirct view</label>
      </DialogTitle>
    )}
  </Dialog>
);

ViewChangeLoader.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(ViewChangeLoader);
