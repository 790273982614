// import { createPath } from 'history';
import React, { useEffect } from 'react';
import { useSecurity } from './context';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

const SecureRoute = props => {
  const history = useHistory();
  const { isAuthenticated } = useSecurity();

  const content = (
    <ErrorBoundary key={JSON.stringify(props.path)}>
      {props.children}
    </ErrorBoundary>
  );

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
      // TODO: { returnPath: createPath(history.location) }
    }
  }, [history, isAuthenticated]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (isAuthenticated) {
    return <Route {...props}>{content}</Route>;
  }
};

export default SecureRoute;
