import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { DistrictViewIcon, StoreViewIcon } from '../Icons';
const viewChangeIconStyles = makeStyles(
  theme => ({
    viewSwitchIcon: {
      position: 'absolute',
      left: `calc(12px + env(safe-area-inset-right))`,
    },
  }),
  { name: 'viewChangeIconStyles' }
);

const ViewSwitchIcon = ({ onClick, viewTypeButton }) => {
  const styles = viewChangeIconStyles();
  const history = useHistory();

  if (viewTypeButton === 'district') {
    return (
      <StoreViewIcon
        className={styles.viewSwitchIcon}
        onClick={event => {
          event.stopPropagation();
          history.push('/');
          onClick();
        }}
      />
    );
  }
  if (viewTypeButton === 'store') {
    return (
      <DistrictViewIcon
        className={styles.viewSwitchIcon}
        onClick={event => {
          event.stopPropagation();
          history.push('/districtView');
          onClick();
        }}
      />
    );
  }
  return null;
};
ViewSwitchIcon.propTypes = {};

export const ViewSwitchIconRouter = withRouter(ViewSwitchIcon);
