import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { makeStyles } from '@material-ui/core';

const useChartStyles = ({ smallChart }) => {
  return makeStyles(() => ({
    root: {
      display: smallChart ? 'flex' : 'block',
      alignItems: 'center',
      '.customizeTooltip': {
        backgroundColor: '#fff',
      },
      '& .recharts-surface': {
        width: 'auto',
        height: 'auto',
        '& .recharts-layer': {
          '& defs': { display: 'none' },
        },
      },
      '& .recharts-wrapper': {
        position: 'absolute',
        height: `${smallChart ? '24px' : '88px'} !important`,
        '& .recharts-cartesian-grid': {
          '& .recharts-cartesian-grid-horizontal': {
            '& line:first-child': {
              strokeOpacity: smallChart ? '0!important' : 'inherit',
            },
            '& line:last-child': {
              strokeOpacity: smallChart ? '0!important' : 'inherit',
              // strokeOpacity: '0!important',
            },
          },
          '& .recharts-cartesian-grid-vertical': {
            '& line:nth-last-child(2)': {
              strokeOpacity: '0!important',
            },
            '& line:last-child': {
              strokeOpacity: '0!important',
            },
          },
        },

        '& .xAxis': {
          '& .recharts-cartesian-axis-tick-line:only-child': {
            display: 'none',
          },
        },
      },
    },
  }));
};

const CustomizedAxisTick = props => {
  const { values, x, y, payload, index } = props;
  const { value: name } = payload;
  const xAxisValue =
    values[index] > 0 ? `+${values[index]}` : `${values[index]}`;
  return (
    <text
      orientation="bottom"
      width="276"
      height="30"
      type="category"
      x={x}
      y={y}
      stroke="none"
      fill="rgba(0, 0, 0, 0.65)"
      class="recharts-text recharts-cartesian-axis-tick-value"
      text-anchor="middle"
    >
      <tspan
        x={x}
        dy="0.71em"
        style={{
          color: 'rgba(0, 0, 0, 0.65)',
          fontWeight: 400,
          fontSize: 8,
        }}
      >
        {name}
      </tspan>
      <tspan
        x={x}
        dy="1.5em"
        style={{
          color: '#000000',
          fontWeight: 600,
          fontSize: 9,
        }}
      >
        {xAxisValue}
      </tspan>
    </text>
  );
};

const PositiveNegativeBarChart = ({ smallChart = false, data }) => {
  const [chartData, setChartData] = useState([]);
  const styles = useChartStyles({ smallChart })();

  useEffect(() => {
    let finalChartData = [];
    data &&
      Object.keys(data).forEach(key => {
        finalChartData.push({
          name: key.toUpperCase(),
          value: data[key],
          fill:
            data[key] > 0
              ? '#3EC10E'
              : data[key] < 0
              ? '#ED2828'
              : 'rgba(0, 0, 0, 0.04)',
        });
      });
    setChartData(finalChartData);
  }, [data]);

  return (
    <ResponsiveContainer className={styles.root} width="100%">
      <BarChart
        data={chartData}
        margin={{
          top: 0,
          right: smallChart ? -60 : -40,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid
          stroke="rgba(0, 0, 0, 0.16)"
          strokeWidth={1}
          interval={0}
        />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={
            smallChart ? (
              false
            ) : (
              <CustomizedAxisTick
                values={chartData.map(({ value }) => value)}
              />
            )
          }
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          orientation="right"
          tickCount={3}
          tick={!smallChart}
          tickFormatter={value => (value > 0 ? '+' : value < 0 ? '-' : '0')}
        />
        <Bar
          dataKey="value"
          fill="#82ca9d"
          radius={[2, 2, 0, 0]}
          barSize={30}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PositiveNegativeBarChart;
